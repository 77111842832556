import { useEffect, useState } from 'react'
import { snackbarShowMessage } from '@/components/Snackbar'
import sendEmailFE from '../utils/sendEmailFE'
import classnames from 'classnames'

import styles from './index.module.scss'

function CPC() {
  const [subscribeEmail, setSubscribeEmail] = useState('')

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubscribe = async () => {
    if (validateEmail(subscribeEmail)) {
      await sendEmailFE(subscribeEmail, 'Subscribe')
      snackbarShowMessage('Subscribe successfully!', 'success')
    } else {
      snackbarShowMessage('Please enter correct email!')
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.banner}>
        <img
          className={styles.logo}
          src="/svg/logo.svg"
          alt=""
          onClick={() => {
            location.href = '/'
          }}
        />
        <img className={styles.bannerImg} src="/img/bg2.png" alt="" />
        <div className={styles.container}>
          <div className={styles.title}>
            Fueling <span style={{ color: '#DD473F' }}>Innovations</span>, <br />
            Empowering Dreams.
          </div>
          <div className={styles.subTitle}>
            At CPC, we bridge visionary ideas with strategic investments. Join us and amplify your voice in the world of
            startups.
          </div>
          <div
            className={styles.button3}
            onClick={() => {
              location.href = '/contact'
            }}
          >
            <span>Contact Us</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
              <path
                d="M6.93317 21.0833L5.4165 19.5667L15.8165 9.16667H6.49984V7H19.4998V20H17.3332V10.6833L6.93317 21.0833Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={styles.paragraph1}>
        <div className={styles.paragraph1_left} style={{ width: '50%' }}>
          <div className={styles.sequence}>01/</div>
          <div className={styles.fs56}>Elite Startup Venture Club | Founded by Stanford Alumni</div>
        </div>
        <div className={classnames(styles.fs16, styles.paragraph1_right)} style={{ width: '50%' }}>
          Established in April 2015 by Stanford affiliates, CPC-Cardinal Pitch Club is the vanguard of uniting
          early-stage startups with esteemed, entrepreneur-friendly VCs and CVCs. Our investors include industry giants
          such as Sequoia, Greylock, NEA, Intel Capital, and more, with an impressive $92 million raised. With a
          thriving community of 1,700 members and counting, we've facilitated pitches from 121 companies spanning
          diverse sectors like AI, AR/VR, Fintech, Healthcare, and Enterprise.
        </div>
      </div>
      <div className={styles.paragraph2}>
        <div className={styles.paragraph2_left} style={{ width: '40%' }}>
          <img src="/img/pic1.png" />
        </div>
        <div className={styles.paragraph2_right} style={{ width: '60%' }}>
          <div className={styles.sequence}>02/ ABOUT US</div>
          <div className={styles.fs56}>Empowering Dreams, Amplifying Voices</div>
          <div className={styles.fs16}>
            CPC empowers individuals with bold dreams and innovative ideas, providing a platform for them to be heard.
            In a world often unkind to new creations, we believe that the time to risk something is the time of
            discovery and defense of fresh ideas. Anyone with the aspiration to pitch can find a home here—because we
            believe, "Anyone can pitch."
          </div>
        </div>
      </div>
      <div className={styles.paragraph3}>
        <div className={styles.paragraph3_left} style={{ width: '50%' }}>
          <div className={styles.sequence}>03/ Stay Updated</div>
          <div className={styles.fs56} style={{ color: '#fff', marginTop: 10 }}>
            CPC in the News
          </div>
          <div className={styles.fs16} style={{ color: '#fff', marginTop: 10 }}>
            Discover the latest happenings and insights in the startup world. Recently, Professor Jan Liphardt at
            Stanford University shared intriguing observations at CPC Crypto DevCon. Learn more about the dynamics of
            information disclosure and our commitment to innovation.
          </div>
        </div>
        <div className={styles.paragraph3_left} style={{ width: '50%' }}>
          <img src="/img/pic2.png" />
        </div>
      </div>

      <div className={styles.paragraph4}>
        <div className={styles.header}>
          <div>
            <div className={styles.sequence}>04/ Meet the CPC Team</div>
            <div className={styles.fs56} style={{ marginTop: 10 }}>
              Passionate Advocates
              <br /> for Your Success
            </div>
          </div>
          <div
            className={styles.button2}
            onClick={() => {
              location.href = '/contact'
            }}
          >
            Contact US{' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path
                d="M1.93317 14.0833L0.416504 12.5667L10.8165 2.16667H1.49984V0H14.4998V13H12.3332V3.68333L1.93317 14.0833Z"
                fill="#0D0D0D"
              />
            </svg>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Leader</div>
              <img className={styles.itemPic} src="/img/pic3.png" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Tamish Pulappadi</div>
              <div className={styles.itemSubTitle}>
                Stanford Computer Science graduate with a GPA of 3.73, Tamish Pulappadi excels as the Co-Founder and
                Developer at Referral Matters, showcasing a unique blend of academic and business expertise.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Leader</div>
              <img className={styles.itemPic} src="/img/pic4.png" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Charles Legaspi</div>
              <div className={styles.itemSubTitle}>
                Stanford Economics student, Management Science & Engineering minor, showcasing business acumen through
                roles at Craniometrix and Webasto Charging Systems, Inc. Leadership in Stanford ASES and Stanford BASES.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Leader</div>
              <img className={styles.itemPic} src="/img/pic5.png" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Manan Gupta</div>
              <div className={styles.itemSubTitle}>
                Stanford student excelling in Mathematical and Computational Science, with business acumen honed as a
                Product Manager Intern at Bayt.com and Analyst Intern at Fieldfisher Capital.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.paragraph4}>
        <div className={styles.header}>
          <div>
            <div className={styles.sequence}>05/ Meet the CPC Team</div>
            <div className={styles.fs56} style={{ marginTop: 10 }}>
              Guiding Us to Where <br />
              We Are Today
            </div>
          </div>
          <div
            className={styles.button2}
            onClick={() => {
              location.href = '/contact'
            }}
          >
            Contact US{' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path
                d="M1.93317 14.0833L0.416504 12.5667L10.8165 2.16667H1.49984V0H14.4998V13H12.3332V3.68333L1.93317 14.0833Z"
                fill="#0D0D0D"
              />
            </svg>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic6.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Marco Lorenzon</div>
              <div className={styles.itemSubTitle}>
                Economics undergrad skilled in startup acceleration, True Ventures fellowship, and JP Morgan investment
                banking. Teaches at Stanford, passionate about food and health innovation.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic7.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Bruno Yoshimura</div>
              <div className={styles.itemSubTitle}>
                Web entrepreneur since 2001, backed by Accel Partners and Kaszek Ventures. Stanford GSB MBA. Expert in
                building ventures, creating teams, and investing in early-stage entrepreneurs.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic8.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Mahak Garg</div>
              <div className={styles.itemSubTitle}>
                GSB student and electronics engineer experienced in finance. Passionate about manufacturing, plant-based
                foods, and finance.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic9.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Erhan Eren</div>
              <div className={styles.itemSubTitle}>
                Corporate leader with a decade of experience in technology management, business development, and AI.
                Invests in AI, smart home, VR technologies, Oil & Gas, and Aerospace Industries.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic10.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Sean Li</div>
              <div className={styles.itemSubTitle}>
                Stanford master’s student in Management Science & Engineering, combining academic prowess with a focus
                on entrepreneurship and venture capital.
              </div>
            </div>
          </div>

          <div className={styles.termItem}>
            <div className={styles.itemHeader}>
              <div className={styles.itemTag}>Former Leader</div>
              <img className={styles.itemPic} src="/img/pic21.png?v=1" />
            </div>
            <div className={styles.itemBody}>
              <div className={styles.itemTitle}>Ishan Gandhi</div>
              <div className={styles.itemSubTitle}>
                Ishan received his Engineering B.S. in 2024, and now works as a Business Analyst in the New York offices
                of McKinsey & Company.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.paragraph5}>
        <div className={styles.sequence} style={{ color: '#fff' }}>
          06/ Major Events
        </div>
        <div className={styles.fs56} style={{ color: '#fff', marginTop: 10 }}>
          Where Innovation Takes <br />
          Center Stage
        </div>
        <div className={styles.fs16} style={{ color: '#fff', marginTop: 10 }}>
          Subscribe now to stay in the loop with our upcoming events. CPC Crypto DevCon: Immerse yourself in 9 Pitch
          Nights featuring top VCs and CVCs in Silicon Valley, including Sequoia, Greylock, Accel, NEA, Intel Capital,
          and many others. Witness the brilliance of 121 companies in fields such as AI, AR/VR, Fintech, Healthcare, and
          Enterprise, with over $92 million raised to date. CPC Cryptocurrency and Exchange Conference.
        </div>
        {/* <div className={styles.inputWrap}>
          <input
            className={styles.input1}
            placeholder="Email"
            onChange={(e) => {
              setSubscribeEmail(e.target.value)
            }}
          />
          <div className={styles.button1} style={{ marginTop: '0' }} onClick={handleSubscribe}>
            <span>Subscribe Now</span>
          </div>
        </div> */}
      </div>

      <div className={styles.paragraph6}>
        <div className={styles.sequence}>07/ CPC Partners</div>
        <div className={styles.fs56} style={{ color: '#fff', marginTop: 10 }}>
          Uniting Forces for Innovation
        </div>
        <div className={styles.innovations}>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic12.png')" }}></div>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic13.png')" }}></div>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic14.png')" }}></div>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic15.png')" }}></div>
        </div>
        <div className={styles.innovations} style={{ marginTop: 0 }}>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic16.png')" }}></div>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic17.png')" }}></div>
          <div className={styles.innovationItem1} style={{ backgroundImage: "url('/img/pic18.png')" }}></div>
          <div className={styles.innovationItem} style={{ backgroundImage: "url('/img/pic19.png')" }}></div>
        </div>
      </div>

      <div className={styles.paragraph7}>
        <div className={styles.fs56} style={{ color: '#fff' }}>
          Your opinions are important <br />
          to us. Contact us now.
        </div>
        <div
          className={styles.button4}
          onClick={() => {
            location.href = '/contact'
          }}
        >
          Contact Us{' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
            <path
              d="M7.43317 21.0833L5.9165 19.5667L16.3165 9.16667H6.99984V7H19.9998V20H17.8332V10.6833L7.43317 21.0833Z"
              fill="#DD473F"
            />
          </svg>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.row1}>
          <div className={styles.column1}>
            <img className={styles.logo} src="/svg/logo.svg" alt="" />
            <div className={styles.text}>Copyright 2024 Cardinal Pitch Club (“CPC”) All Rights Reserved.</div>
            <div className={styles.social}>
              <svg
                onClick={() => {
                  location.href = 'https://www.facebook.com/cardinalpitchclub/'
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_1_690)">
                  <path
                    d="M5.27131 10.652H7.46363V19.6774C7.46363 19.8556 7.60802 20 7.78621 20H11.5034C11.6816 20 11.826 19.8556 11.826 19.6774V10.6945H14.3462C14.5101 10.6945 14.648 10.5715 14.6667 10.4088L15.0494 7.08606C15.06 6.99465 15.031 6.9031 14.9698 6.83452C14.9086 6.76587 14.821 6.72658 14.7291 6.72658H11.8261V4.64374C11.8261 4.01587 12.1641 3.69748 12.831 3.69748C12.926 3.69748 14.7291 3.69748 14.7291 3.69748C14.9072 3.69748 15.0516 3.55303 15.0516 3.3749V0.324968C15.0516 0.146774 14.9072 0.0023871 14.7291 0.0023871H12.1132C12.0948 0.00148387 12.0538 0 11.9934 0C11.5396 0 9.96196 0.0890967 8.71576 1.23555C7.33499 2.506 7.52692 4.02716 7.57279 4.2909V6.72652H5.27131C5.09312 6.72652 4.94873 6.8709 4.94873 7.0491V10.3294C4.94873 10.5075 5.09312 10.652 5.27131 10.652Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_690">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                onClick={() => {
                  location.href = 'https://twitter.com/cardinalpitch'
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.29 17.9998C13.837 17.9998 17.965 11.8434 17.965 6.50517C17.965 6.32992 17.965 6.15599 17.953 5.98271C18.756 5.41167 19.449 4.70266 20 3.8914C19.252 4.21827 18.457 4.43263 17.644 4.52714C18.5 4.02207 19.141 3.22858 19.448 2.29228C18.642 2.76289 17.761 3.09502 16.842 3.27323C15.288 1.64676 12.689 1.56796 11.036 3.09794C9.971 4.08445 9.518 5.55532 9.849 6.95829C6.55 6.79486 3.476 5.26097 1.392 2.73759C0.303 4.5836 0.86 6.94429 2.663 8.12968C2.01 8.11097 1.371 7.93788 0.8 7.6248V7.67606C0.801 9.59889 2.178 11.2545 4.092 11.6355C3.488 11.798 2.854 11.8215 2.24 11.7044C2.777 13.3505 4.318 14.478 6.073 14.5105C4.62 15.6349 2.825 16.2454 0.977 16.2434C0.651 16.2425 0.325 16.2237 0 16.1853C1.877 17.3707 4.06 17.9998 6.29 17.9969"
                  fill="white"
                />
              </svg>
              <svg
                onClick={() => {
                  location.href = 'https://www.linkedin.com/company/cardinal-pitch-club-cpc/'
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_1_698)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 20H16V13.001C16 11.081 15.153 10.0098 13.634 10.0098C11.981 10.0098 11 11.126 11 13.001V20H7V7H11V8.46191C11 8.46191 12.255 6.25977 15.083 6.25977C17.912 6.25977 20 7.98611 20 11.5581V20ZM2.442 4.9209C1.093 4.9209 0 3.81896 0 2.45996C0 1.10196 1.093 0 2.442 0C3.79 0 4.883 1.10196 4.883 2.45996C4.884 3.81896 3.79 4.9209 2.442 4.9209ZM0 20H5V7H0V20Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_698">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className={styles.column2}>
            <img className={styles.logo} src="/svg/logo.svg" alt="" />
            <div style={{ fontWeight: 'bold' }}>Contact Us</div>
            <div
              className={styles.contactInformation}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open('mailto:bril@cpitch.com')
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20V4H22V20H2ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z" fill="#DD473F" />
              </svg>{' '}
              olivia@cpitch.com
            </div>
          </div>
        </div>
        <div className={styles.row2}>
          <span className={styles.text}>
            *While Cardinal Pitch Club, Inc. (“CPC”) was initially founded by Stanford alumni, Cardinal Pitch Club is
            not endorsed by, involved with or otherwise affiliated with Stanford University. Cardinal Pitch Club is an
            independent, for-profit organization that is unrelated to the activities or mission of Stanford University.
          </span>
        </div>
        <div className={styles.copyright}>
          <div className={styles.text}>Copyright 2024 Cardinal Pitch Club (“CPC”) All Rights Reserved.</div>
          <div className={styles.social}>
            <svg
              onClick={() => {
                location.href = 'https://www.facebook.com/cardinalpitchclub/'
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_1_690)">
                <path
                  d="M5.27131 10.652H7.46363V19.6774C7.46363 19.8556 7.60802 20 7.78621 20H11.5034C11.6816 20 11.826 19.8556 11.826 19.6774V10.6945H14.3462C14.5101 10.6945 14.648 10.5715 14.6667 10.4088L15.0494 7.08606C15.06 6.99465 15.031 6.9031 14.9698 6.83452C14.9086 6.76587 14.821 6.72658 14.7291 6.72658H11.8261V4.64374C11.8261 4.01587 12.1641 3.69748 12.831 3.69748C12.926 3.69748 14.7291 3.69748 14.7291 3.69748C14.9072 3.69748 15.0516 3.55303 15.0516 3.3749V0.324968C15.0516 0.146774 14.9072 0.0023871 14.7291 0.0023871H12.1132C12.0948 0.00148387 12.0538 0 11.9934 0C11.5396 0 9.96196 0.0890967 8.71576 1.23555C7.33499 2.506 7.52692 4.02716 7.57279 4.2909V6.72652H5.27131C5.09312 6.72652 4.94873 6.8709 4.94873 7.0491V10.3294C4.94873 10.5075 5.09312 10.652 5.27131 10.652Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_690">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              onClick={() => {
                location.href = 'https://twitter.com/cardinalpitch'
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.29 17.9998C13.837 17.9998 17.965 11.8434 17.965 6.50517C17.965 6.32992 17.965 6.15599 17.953 5.98271C18.756 5.41167 19.449 4.70266 20 3.8914C19.252 4.21827 18.457 4.43263 17.644 4.52714C18.5 4.02207 19.141 3.22858 19.448 2.29228C18.642 2.76289 17.761 3.09502 16.842 3.27323C15.288 1.64676 12.689 1.56796 11.036 3.09794C9.971 4.08445 9.518 5.55532 9.849 6.95829C6.55 6.79486 3.476 5.26097 1.392 2.73759C0.303 4.5836 0.86 6.94429 2.663 8.12968C2.01 8.11097 1.371 7.93788 0.8 7.6248V7.67606C0.801 9.59889 2.178 11.2545 4.092 11.6355C3.488 11.798 2.854 11.8215 2.24 11.7044C2.777 13.3505 4.318 14.478 6.073 14.5105C4.62 15.6349 2.825 16.2454 0.977 16.2434C0.651 16.2425 0.325 16.2237 0 16.1853C1.877 17.3707 4.06 17.9998 6.29 17.9969"
                fill="white"
              />
            </svg>
            <svg
              onClick={() => {
                location.href = 'https://www.linkedin.com/company/cardinal-pitch-club-cpc/'
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_1_698)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 20H16V13.001C16 11.081 15.153 10.0098 13.634 10.0098C11.981 10.0098 11 11.126 11 13.001V20H7V7H11V8.46191C11 8.46191 12.255 6.25977 15.083 6.25977C17.912 6.25977 20 7.98611 20 11.5581V20ZM2.442 4.9209C1.093 4.9209 0 3.81896 0 2.45996C0 1.10196 1.093 0 2.442 0C3.79 0 4.883 1.10196 4.883 2.45996C4.884 3.81896 3.79 4.9209 2.442 4.9209ZM0 20H5V7H0V20Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_698">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CPC
