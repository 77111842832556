export default async function sendEmail(email, content, fullName?) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      content,
      fullName,
    }),
  }
  await fetch('api/v1/sendEmail', options).catch((err) => console.error(err))
}
